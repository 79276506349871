import { Injectable } from '@angular/core';
import { Headers, RequestOptions, Http } from '@angular/http';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier'
import * as tools from 'src/app/tools'
import { apiConfig } from 'src/config'
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class CommService {
  body=[];
  // data=[{PN:'123', nazev:'cover', provoz:'Kuřim', status:'Standardní'},{PN:'456', nazev:'modul', provoz:'Jedlová', status:'Vysoká zmetkovost'},
  // {PN:'789', nazev:'umri', provoz:'qwe', status:'bude'}]
  selectedprodukt:any;
  produktid:number;
  komponentid:number;
  testerid:number;
  analyzaid:string;
  zavadyView:any;
  otazkyView:any;
  pracovnikid:number;
  selectednastaveni:string;
  vybranezarizeni={"id":""};
  auditId={"auditId":""};
  kartaB={"id":""};

  data_summ={"summ_reklamace":"", "summ_vyroba":"", "summ_udrzby":""};

  // API location
  path:string = apiConfig.url;

  private authUser:any = {};
  private authToken:string;
  private notifier: NotifierService;
  private options: RequestOptions = null;

  constructor(private http: Http, private router:Router, notifier: NotifierService) {
    this.notifier = notifier
    // revalidation
    this.revalidate()
  }

  // AUTH methods --------------------------------------------

  login(data) {
    return this.http.post(this.path+'/login', data)
  }

  me() {
    return this.http.get(this.path+'/me', this.options)
  }

  isLoggedIn() {
    return !!this.authUser.id
  }

  getAuthUser() {
    return this.authUser
  }

  saveUser(user) {
    this.authUser = user
  }

  logout() {
    this.authUser = {}
    this.authToken = ''
    tools.deleteCookie('AuthToken')
    this.refreshOptions()
    this.router.navigate(['/login'])
  }

  refreshOptions() {
    let headers = new Headers();
    if (this.authToken) {
      headers.append('X-Auth-Token', `${this.authToken}`);
    }
    this.options = new RequestOptions({headers});
  }

  saveToken(token, remember = 1) {
    this.authToken = token
    // save in cookies for revalidation
    const expirationMinutes = remember ? 60 * 24 * 14 : 60
    tools.setCookie('AuthToken', token, expirationMinutes)
    this.refreshOptions()
  }

  revalidate() {
    const token = tools.getCookie('AuthToken')
    if (token) {
      this.authToken = token
      this.refreshOptions()
      this.me().subscribe(
        (response: any) => {
          const responseData = JSON.parse(response._body || '{}')
          if (responseData && responseData.error && !responseData.id) {
            console.error({responseData})
            this.notifier.notify('error', 'Přihlášení vypršelo, je nutné se přihlásit znovu')
            this.router.navigate(['/login'])
          } else {
            this.saveUser(responseData)
            if (this.router.url === '/login') {
              // revalidation on login page -> move to root
              this.router.navigate(['/'])
            }
          }
        },
        (error) => {
          this.notifier.notify('error', 'Chyba při opětovném přihlášení, je nutné se přihlásit znovu')
          this.router.navigate(['/login'])
          console.error({error})
        }
      )
    } else {
      // simply not logged in
      this.router.navigate(['/login'])
    }
  }

  // can the currently logged in user do this?
  can(how = 'read', what = '') {
    if (!(this.authUser || {}).id) {
      return false // not logged in = cannot do anything
    }
    if (this.authUser.isAdmin) {
      return true // admin can do anything
    }
    if (!what) {
      return true // just logged in
    }
    const perm = this.authUser.permissions.find(p => p.permission === what)
    if (!perm) {
      return false // lacking the permission altogether
    }
    return !!perm[how] // cast to boolean
  }

  // exports all CRUD operations for a given endpoint
  crud(endpoint: string) {
    return {
      index: (params: any = {}) => this.http.get(`${this.path}/${endpoint}`, {...this.options, params}),
      get: (id) => this.http.get(`${this.path}/${endpoint}/${id}`, this.options),
      post: (data) => this.http.post(`${this.path}/${endpoint}`, data, this.options),
      put: (data) => this.http.put(`${this.path}/${endpoint}/${data.id}`, data, this.options),
      delete: (id) => this.http.delete(`${this.path}/${endpoint}/${id}`, this.options)
    }
  }

  // crud entities
  users() { return this.crud('user') }
  products() { return this.crud('product') }
  components() { return this.crud('component') }
  manufacturing() { return this.crud('manufacturing') }
  device() { return this.crud('device') }
  reclamation() { return this.crud('reclamation') }
  cardA() { return this.crud('card-a') }
  cardB() { return this.crud('card-b') }
  maintenance() { return this.crud('maintenance') }
  plannedMaintenance() { return this.crud('planned-maintenance') }
  audit() { return this.crud('audit') }

  // codelists
  manufacturers() {return this.crud('manufacturer')}
  metodyAuditu() {return this.crud('metoda-auditu')}
  oblastAuditu() {return this.crud('oblast-auditu')}
  otazkyAuditu() {return this.crud('otazka-auditu')}
  provozovny() {return this.crud('provozovna')}
  typyVyrobku() {return this.crud('product-type')}
  typyVyroby() {return this.crud('manufacture-type')}
  typyReklamace() {return this.crud('reclamation-type')}
  typyReklamaceStatus() {return this.crud('reclamation-state')}
  typyAuditu() {return this.crud('audit-type')}
  typyUdrzby() {return this.crud('maintenance-type')}
  typyZarizeni() {return this.crud('device-type')}
  typyZavad() {return this.crud('fault-type')}
  uzivatelskePozice() {return this.crud('user-position')}



//NASTENKA

getDatabase(){
  return this.http.get(this.path+'/getdatabase', this.options)
}

getSummUdrzba(){
  return this.http.get(this.path+'/getsummudrzba', this.options)
}

getSummReklamace(){
  return this.http.get(this.path+'/getsummreklamace', this.options);
}

getSummVyroba(){
  return this.http.get(this.path+'/getsummvyroba', this.options);
}


//OSTATNI

  fileUpload(file){
    return this.http.post(this.path+'/upload', file, this.options);
  }


  getAuditLpa(produkt){
    return this.http.post(this.path+'/getauditlpa', produkt, this.options)
  }

  getAuditLpaReport(produkt){
    return this.http.post(this.path+'/getauditlpareport', produkt, this.options)
  }


  getAnalyzaAuditId(produkt){
    return this.http.post(this.path+'/getanalyzaauditid', produkt, this.options)
  }

  getZavadyKomponent(){
    return this.http.get(this.path+'/getzavadykomponent', this.options)
  }

  getZavadyVyroba(){
    return this.http.get(this.path+'/getzavadyvyroba', this.options)
  }

  getAnalyzaZavad(){
    return this.http.get(this.path+'/getanalyzazavad', this.options)
  }

  getAnalyzaZavadVyroba(){
    return this.http.get(this.path+'/getanalyzazavadvyroba', this.options)
  }

  getAnalyzaZavadFiltr(produkt){
    return this.http.post(this.path+'/getanalyzazavadfiltr', produkt, this.options)
  }

  getAnalyzaZavadChart(produkt){
    return this.http.post(this.path+'/getanalyzazavadchart', produkt, this.options)
  }

  postNastaveni(data, cesta){
    return this.http.post(this.path+'/nastaveni/'+cesta, data, this.options)
  }

  // ZARIZENI //
  getTPM(){
    return this.http.get(this.path+'/tpm', this.options)
  }

  // VYROBA //
  getAnalyzaZavadPn(produkt){
    return this.http.post(this.path+'/getanalyzazavadpn', produkt, this.options)
  }

  getAnalyzaZavadPnSmenaSloucene(produkt){
    return this.http.post(this.path+'/getvanalyzazavad', produkt, this.options)
  }

  getPocetZavad(produkt){
    return this.http.post(this.path+'/getpocetzavad', produkt, this.options)
  }

// KVALITA //

getKartaA_pn(produkt){
  return this.http.post(this.path+'/getkartaapn', produkt, this.options)
}

getKartaB(produkt){
  return this.http.post(this.path+'/getkartab', produkt, this.options)
}

updateKartaB(produkt){
  return this.http.post(this.path+'/updatekartab', produkt, this.options)
}

postReklamace(produkt){
  return this.http.post(this.path+'/postreklamace', produkt, this.options);
}


//AUDIT

getAudit(produkt){
  return this.http.post(this.path+'/getaudit', produkt, this.options)
}

updateAudit(produkt){
  return this.http.post(this.path+'/updateaudit', produkt, this.options)
}

}
