import { Component, OnInit, OnDestroy } from '@angular/core'
import { NgForm } from '@angular/forms'
import BaseEditationComponent from 'src/app/class/BaseEditationComponent'
import 'rxjs/add/operator/filter'
import { Product, User } from 'src/app/types'

const defaultKartaB = { note: '', datum: null, osoba: null }

@Component({
  selector: 'app-vyroba-editace',
  templateUrl: './editace.component.html',
  styleUrls: ['../../app.component.css']
})
export class EditaceVyrobaComponent extends BaseEditationComponent implements OnInit, OnDestroy {
  data = {
    id: null,
    pn: null,
    batch: null,
    sestavy_id: null,
    pripravek: null,
    osoba: null,
    operatorJmeno: '',
    dateZacatku: null,
    dateUkonceni: null,
    totalOK: null,
    totalNOK: null,
    OkNokRatio: null,
    zavady: [],
    kartaA: [],
    kartaB: {...defaultKartaB},
  }

  requiredFields = ['pn']
  users: User[] = []; 
  product: Product = {id: 0, pn: '', zavady: [], rozmery: []};

  objectKeys = Object.keys;
  fmFieldMappings = {
    imageField: 'image',
  }

  getCommEntity() {
    return this.comm.manufacturing();
  }

  // ngOnInit() {
  //   super.ngOnInit()
  //   this.route.queryParams
  //   .filter(params => params.type)
  //   .subscribe(params => {
  //     this.data.typ = params.type;
  //   })
  // }

  loadData() {
    super.loadData()
    this.loadUsers()
  }
  
  afterLoad() {
    this.data.OkNokRatio = (Math.round((this.data.totalNOK ? (this.data.totalOK / this.data.totalNOK) : (this.data.totalOK ? this.data.totalOK : 0)) * 100) / 100) + ' : ' + (this.data.totalNOK ? 1 : 0)
    if (this.data.dateZacatku) this.data.dateZacatku = new Date(this.data.dateZacatku)
    if (this.data.dateUkonceni) this.data.dateUkonceni = new Date(this.data.dateUkonceni)
    if (!this.data.kartaB) {
      this.data.kartaB = {...defaultKartaB}
    }
    this.loadProduct(this.data.sestavy_id)
    this.refreshOperatorJmeno()
  }

  getHeading() {
    return this.id ? this.data.pn ? `Editace výroby ${this.data.pn}` : 'Načítám výrobu...' : 'Nová výroba'
  }

  afterSave() {
    this.router.navigate(['/rozpracovana-vyroba']);
  }

  loadProduct(id){
    this.comm.products().get(id).subscribe(
      (response) => { this.product = response.json() },
      (error) => console.error({error})
    )
  }

  loadUsers(){
    this.comm.users().index().subscribe(
      (response) => { 
        this.users = response.json() 
        this.refreshOperatorJmeno()
      },
      (error) => console.error({error})
    )
  }

  changeOperator(newOperator) {
    this.data.osoba = newOperator
    this.refreshOperatorJmeno()
  }

  refreshOperatorJmeno() {
    if (this.users) {
      const user = this.users.find(user => user.cislo === +this.data.osoba)
      if (user) {
        const name = user.jmeno || ''
        this.data.operatorJmeno = name
      }
    }
  }

  parseFormToData(form: NgForm) {
    if (form.value.batch!=''){ this.data.batch = form.value.batch };
    if (form.value.dateUkonceni!=''){ this.data.dateUkonceni = form.value.dateUkonceni };
    if (form.value.dateZacatku!=''){ this.data.dateZacatku = form.value.dateZacatku };
    // if (form.value.pn!=''){ this.data.pn = form.value.pn }; // not editable
    if (form.value.pripravek!=''){ this.data.pripravek = form.value.pripravek };
    if (form.value.totalOK!=''){ this.data.totalOK = form.value.totalOK };
  }

  newZavada() {
    this.data.zavady.push({zavadaID: null, name: '', date: null})
  }
  changeZavada(i, param, value) {
    this.data.zavady[i][param] = value
    if (param === 'zavadaID') {
      const zavady = this.product.zavady
      const name = (zavady.find(zavada => zavada.id === +value) || {}).name || ''
      if (name) {
        this.data.zavady[i].name = name
      }
    }
  }
  removeZavada(i) {
    this.data.zavady = this.data.zavady.filter((z, index) => i !== index)
  }
  
  newKartaA() {
    this.data.kartaA.push({ measure_id: null, measure_data: '', timestamp: null, user_controlled: null})
  }
  changeKartaA(i, param, value) {
    this.data.kartaA[i][param] = value
  }
  removeKartaA(i) {
    this.data.kartaA = this.data.kartaA.filter((z, index) => i !== index)
  }
  
  changeKartaB(param, value) {
    this.data.kartaB[param] = value
  }
  removeKartaB() {
    this.data.kartaB = {...defaultKartaB}
    console.log('🚀 ~ EditaceVyrobaComponent ~ removeKartaB ~ defaultKartaB:', defaultKartaB)
  }
}
