import { Component, OnInit, OnDestroy } from '@angular/core'
import { NgForm } from '@angular/forms'
import { escapeRegExp } from 'src/app/tools'
import 'rxjs/add/operator/filter'
import BaseEditationComponent from 'src/app/class/BaseEditationComponent'

@Component({
  selector: 'app-editace',
  templateUrl: './editace.component.html',
  styleUrls: ['../../app.component.css']
})
export class EditaceProduktyComponent extends BaseEditationComponent implements OnInit, OnDestroy {
  data = {
    id: null, "pn":"", "name":"", "provozovny": [], "komponenty": [], "zavady": [], "rozmery": [],
    "typ": "1", "typSub": "1", normohodina: '', "stoplinePoSobe":"", "stoplineSmena": "", "image": "",
    pracPokyny: "", kontPokyny: "", vykres: "", merePokyny: "", vizualni: "", info: "",
    kartaA: 0, kartaB: 0, kartaA_refValue: null, kartaA_refValueplus: null, kartaA_refValueminus: null, hidden_analyza: 0
  }
  requiredFields = ['pn']
  provozovny=[];
  komponenty=[];
  typvyroby=[];
  typzavady=[];
  typyVyrobku=[];
  componentFilterText="";
  objectKeys = Object.keys;
  fmFields = {
    pracPokyny: 'Pracovní pokyny',
    kontPokyny: 'Kontrolní instrukce',
    vykres: 'Výkres',
    merePokyny: 'Měřící pokyny',
    vizualni: 'Vizuální pomůcky'
  }
  fmFieldMappings = {
    imageField: 'image',
    pracPokynyField: 'pracPokyny',
    kontPokynyField: 'kontPokyny',
    vykresField: 'vykres',
    merePokynyField: 'merePokyny',
    vizualniField: 'vizualni'
  }

  getCommEntity() {
    return this.comm.products();
  }

  ngOnInit() {
    super.ngOnInit()
    this.route.queryParams
    .filter(params => params.type)
    .subscribe(params => {
      this.data.typ = params.type;
    })
  }

  loadData() {
    super.loadData()
    this.loadProvozovny()
    this.loadTypvyroby()
    this.loadTypzavady()
    this.loadKomponenty()
    this.loadTypVyrobku()
    this.getFilteredComponents = this.getFilteredComponents.bind(this)
  }

  getHeading() {
    return this.id ? this.data.pn ? `Editace sestavy ${this.data.pn}` : 'Načítám sestavu...' : 'Nová sestava PN'
  }

  afterSave() {
    this.router.navigate(['/produkty/sestavy']);
  }

  loadProvozovny(){
    this.comm.provozovny().index().subscribe(
      (response) => { this.provozovny = response.json() },
      (error) => console.error({error})
    )
  }

  loadTypvyroby(){
    this.comm.typyVyroby().index().subscribe(
      (response) => { this.typvyroby = response.json() },
      (error) => console.error({error})
    )
  }

  loadTypzavady(){
    this.comm.typyZavad().index().subscribe(
      (response) => { this.typzavady = response.json() },
      (error) => console.error({error})
    )
  }

  loadKomponenty(){
    this.comm.components().index().subscribe(
      (response) => { this.komponenty = response.json() },
      (error) => console.error({error})
    )
  }

  loadTypVyrobku(){
    this.comm.typyVyrobku().index().subscribe(
      (response) => { this.typyVyrobku = response.json() },
      (error) => console.error({error})
    )
  }

  setTypVyroby(event: any) {
    this.data.typSub = event.target.value
  }

  checkProvozovna(provozovna) {
    if (this.data.provozovny.indexOf(provozovna.id) > -1) { // this is uncheck
      this.data.provozovny = this.data.provozovny.filter(pid => pid !== provozovna.id)
    } else { // this is check
      this.data.provozovny.push(provozovna.id)
    }
  }

  setComponentFilterText(event: any) {
    this.componentFilterText = event.target.value
  }

  // filter what is matching search query + what is selected
  getFilteredComponents() {
    const regex = new RegExp(escapeRegExp(this.componentFilterText), 'i')
    const matching = k => k.name.search(regex) >= 0 || k.pn.search(regex) >= 0
    const selected = k => this.data.komponenty.indexOf(k.id) > -1
    return this.komponenty.filter(k => matching(k) || selected(k))
  }

  checkKomponenta(komponenta) {
    if (this.data.komponenty.indexOf(komponenta.id) > -1) { // this is uncheck
      this.data.komponenty = this.data.komponenty.filter(kid => kid !== komponenta.id)
    } else { // this is check
      this.data.komponenty.push(komponenta.id)
    }
  }

  newZavada() {
    this.data.zavady.push({"typ": 1, "name": '', "image": '', "pos": this.data.zavady.length})
  }

  changeZavada(i, param, event) {
    const value = event.target.value
    this.data.zavady[i][param] = value
  }

  removeZavada(i) {
    this.data.zavady = this.data.zavady.filter((z, index) => i !== index)
  }

  newRozmer() {
    this.data.rozmery.push({"value": '', "toleranceMin": '', "toleranceMax": '', "dimension": '', 'note': ''})
  }

  changeRozmer(i, param, event) {
    const value = event.target.value
    this.data.rozmery[i][param] = value
  }

  removeRozmer(i) {
    this.data.rozmery = this.data.rozmery.filter((z, index) => i !== index)
  }



  parseFormToData(form: NgForm) {
    if (form.value.pn!=''){this.data.pn=form.value.pn};
    if (form.value.name!=''){this.data.name=form.value.name};
    if (form.value.info!=''){this.data.info=form.value.info};
    if (form.value.normohodina!='') { this.data.normohodina=form.value.normohodina }
    if (form.value.stoplinePoSobe!='') { this.data.stoplinePoSobe=form.value.stoplinePoSobe }
    if (form.value.stoplineSmena!='') { this.data.stoplineSmena=form.value.stoplineSmena }
    if (form.value.kartaA_refValue!='') { this.data.kartaA_refValue=form.value.kartaA_refValue }
    if (form.value.kartaA_refValueplus!='') { this.data.kartaA_refValueplus=form.value.kartaA_refValueplus }
    if (form.value.kartaA_refValueminus!='') { this.data.kartaA_refValueminus=form.value.kartaA_refValueminus }
  }

  // handles message obtained from Responsive File Manager
  receiveMessage(event) {
    if (!event.data) return
    const {field_id, sender, url} = event.data
    if (sender !== "responsivefilemanager") {
      return
    }
    if (this.fmFieldMappings[field_id]) {
      this.data[this.fmFieldMappings[field_id]] = url
      this.ngxSmartModalService.getModal(field_id + 'FM').close()
    } else if (field_id.match(/zavadaImageField_\d+/)) { // for zavady
      // parse an index from the field id and update data.zavady
      const match = field_id.match(/zavadaImageField_(\d+)/)
      if (match.length > 0) {
        this.data.zavady[+match[1]]['image'] = url
        this.ngxSmartModalService.getModal(field_id + 'FM').close()
      }
    }
  }
}
