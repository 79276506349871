import { Injectable, OnInit, OnDestroy } from '@angular/core'
import { NgForm } from '@angular/forms'
import { CommService } from 'src/app/comm.service'
import { apiConfig } from 'src/config'
import { Router, ActivatedRoute } from '@angular/router'
import { DomSanitizer } from '@angular/platform-browser'
import { NotifierService } from 'angular-notifier'
import { NgxSmartModalService } from 'ngx-smart-modal'
import { DatePipe } from '@angular/common';

@Injectable()
class BaseEditationComponent implements OnInit, OnDestroy {
  public id: number;
  protected sub: any;
  protected entityComm: any;
  public data: any;
  protected requiredFields: string[];
  protected fmFieldMappings: object;
  public objectKeys = Object.keys;

  constructor(
    protected comm: CommService,
    protected router:Router,
    protected route: ActivatedRoute,
    protected notifier: NotifierService,
    protected ngxSmartModalService: NgxSmartModalService,
    protected sanitizer: DomSanitizer,
    protected datePipe: DatePipe
  ) {
    this.entityComm = this.getCommEntity(),
    this.notifier = notifier
  }

// override!
getCommEntity() {
  return null;
}

loadData() {
  this.sub = this.route.params.subscribe(params => {
    this.id = +params['id']
    if (this.id) {
      this.entityComm.get(this.id).subscribe(
        (response) => {
          const responseData = response.json()
          if (responseData.error) {
            this.notifier.notify('error', responseData.message)
            this.router.navigate(['/'])
          } else {
            this.data = responseData;
            this.afterLoad()
            console.log(this.data);
          }
        },
        (error) => {
          const message = (error.json() || {}).message ? error.json().message : 'Chyba při ukládání dat'
          this.notifier.notify('error', message)
          console.error({error})
        }
      )
    }
  })
}

// optionally override
afterLoad() {
  return;
}

ngOnInit() {
  this.loadData()
  this.receiveMessage = this.receiveMessage.bind(this)
  window.addEventListener('message', this.receiveMessage, false)
}

getHeading() {
  return this.id ? this.data.id ? `Editace položky ${this.data.id}` : 'Načítám položku...' : 'Nová položka'
}

afterSave() {
  this.router.navigate(['/']);
}


save() {
  this.entityComm.post(this.data).subscribe(
    (response: any) => {
      const responseData = response.json()
      if (responseData && responseData.error) {
        console.error({responseData})
        this.notifier.notify('error', 'Nastala chyba při ukládání: ' + responseData.message)
      } else {
        this.notifier.notify('success', 'Záznam vytvořen')
        this.afterSave()
      }
    },
    (error) => {
      const message = (error.json() || {}).message ? error.json().message : 'Chyba při ukládání dat'
      this.notifier.notify('error', message)
      console.error({error})
    }
  )
}

update() {
  console.log('🚀 ~ BaseEditationComponent ~ save ~ this.data:', this.data)
  this.entityComm.put(this.data).subscribe(
    (response: any) => {
      const responseData = response.json()
      if (responseData && responseData.error) {
        console.error({responseData})
        this.notifier.notify('error', 'Nastala chyba při ukládání: ' + responseData.message)
      } else {
        this.notifier.notify('success', 'Změny úspěšně uloženy')
      }
    },
    (error) => {
      const message = (error.json() || {}).message ? error.json().message : 'Chyba při ukládání dat'
      this.notifier.notify('error', message)
      console.error({error})
    }
  )
}

validate(data) {
  const errors = this.requiredFields.reduce((errs, field) => {
    return !data[field] ? errs.concat(['Pole ' + field.toUpperCase() + ' je povinné!']) : errs
  }, [])
  return errors
}

setValue(fieldName: string, value: any) {
  this.data[fieldName] = value
}

setSelectValue(event: any, fieldName: string) {
  this.data[fieldName] = event.target.value
}

parseFormToData(form: NgForm) {
  // if (form.value.pn != '') {
    //   this.data.pn = form.value.pn
    // }
  }

  onSubmit(form: NgForm){
    this.parseFormToData(form)

    // validate
    const errors = this.validate(this.data)
    if (errors.length) {
      errors.map(err => this.notifier.notify('error', err))
      return
    }

    if (!this.data.id) {
      this.save()
    } else {
      this.update()
    }
  }

  getFMDialogUrl(field_id, type = 1) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      apiConfig.fmUrl + 'dialog.php?type=' + type + '&field_id=' + field_id + '&lang=cs&popup=0&crossdomain=1&relative_url=0&akey=temporaryStaticAKey_xbn6g5n41d56fgn1'
    );
  }

  // handles message obtained from Responsive File Manager
  receiveMessage(event) {
    if (!event.data) return
    const {field_id, sender, url} = event.data
    if (sender !== "responsivefilemanager") {
      return
    }
    if (this.fmFieldMappings[field_id]) {
      this.data[this.fmFieldMappings[field_id]] = url
      this.ngxSmartModalService.getModal(field_id + 'FM').close()
    }
  }

  fmFieldEdit(field, value) {
    this.data[field] = value
  }


  ngOnDestroy() {
    this.sub.unsubscribe();
    window.removeEventListener("message", this.receiveMessage, false)
  }
}

export default BaseEditationComponent
