import { Component, OnInit } from '@angular/core'
import BaseListComponent from 'src/app/class/BaseListComponent'
import { NgForm } from '@angular/forms'
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-karta-b',
  templateUrl: './list.component.html',
  styleUrls: ['../../app.component.css']
})
export default class ListComponent extends BaseListComponent implements OnInit {
  data: any
  typyVyrobku = []
  datum = {from: null, to: null}
  activeItem;

  ngOnInit() {
    const lastmonth = new Date(new Date().setDate(new Date().getDate()-30))
    this.datum = {
      from: new DatePipe('en-US').transform(lastmonth, 'yyyy-MM-dd'),
      to: new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd')
    }
    super.ngOnInit()
  }

  getCommEntity() {
    return this.comm.cardB()
  }

  loadData() {
    this.entityComm.index(this.datum).subscribe(
      (response) => {
        const responseData = response.json();
        console.log(responseData);
        if (!responseData.error) {
          this.data = responseData
        }
      },
      (error) => {
        const message = (error.json() || {}).message ? error.json().message : 'Chyba při načítání dat'
        this.notifier.notify('error', message)
        console.error({error})
      }
    );
    // load extra data
    this.comm.typyVyrobku().index().subscribe((res) => {this.typyVyrobku=res.json()},(err) => console.log(err));
  }

  getFilters() {
    return this.typyVyrobku.map(type => (
      {label: type.name, func: (item: any) => item.typVyrobku === type.id, value: type.id}
    ))
  }

  getFilteredType() {
    const activeFilter = this.getActiveFilter()
    return activeFilter ? activeFilter.value : 1 // default typVyrobku
  }

  onSubmit(form:NgForm){
    if (form.value.from!='') {this.datum.from = form.value.from}
    if (form.value.to!='') {this.datum.to = form.value.to}
    this.refresh()
  }

  getHeading() {
    return 'Kontrolní karta B'
  }

  goToEdit(item) {
    this.comm.kartaB.id=item;
    console.log(this.comm.kartaB);
    this.router.navigate(['/kvalita/karta-b/editace/'+item]);
  }
}
