import { Component, OnInit, NgZone } from '@angular/core'
import BaseListComponent from 'src/app/class/BaseListComponent'
import { CommService } from 'src/app/comm.service'
import { Router, ActivatedRoute } from '@angular/router'
import { NotifierService } from 'angular-notifier'
import { NgxSmartModalService } from 'ngx-smart-modal'
import { NgForm } from '@angular/forms'
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-lpa',
  templateUrl: './lpa.component.html',
  styleUrls: ['./../../app.component.css']
})

export class LPAComponent extends BaseListComponent implements OnInit {
  typyAuditu = [];
  otazky=[];
  analyzaid={"id":null};
  poznamka;

  data: any;
  datapn: any;
  activeItem: any;
  datum = {from: null, to: null}
  
    constructor(
      protected comm: CommService,
      protected router: Router,
      protected route: ActivatedRoute,
      protected ngxSmartModalService: NgxSmartModalService,
      protected notifier: NotifierService,
      protected zone: NgZone
    ) {
      super(comm, router, route, ngxSmartModalService, notifier)
    }
  
    ngOnInit() {
      const lastmonth = new Date(new Date().setDate(new Date().getDate()-30))
      this.datum = {
        from: new DatePipe('en-US').transform(lastmonth, 'yyyy-MM-dd'),
        to: new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd')
      }
      super.ngOnInit()
    }
  
    getCommEntity() {
      return this.comm.audit()
    }
  
    loadData() {
      this.entityComm.index(this.datum).subscribe(
        (response) => {
          const responseData = response.json()
          if (!responseData.error) {
            this.data = responseData;
            //console.log(this.data);
          }
        },
        (error) => {
          const message = (error.json() || {}).message ? error.json().message : 'Chyba při načítání dat'
          this.notifier.notify('error', message)
          console.error({error})
        }
      );
      // load extra data
      this.comm.typyAuditu().index().subscribe((res) => {this.typyAuditu=res.json()},(err) => console.log(err));
    }
  
    changeColor(){
      var i;
    for (i = 0; i < this.data.length; i++) {
    if (
      (((this.data[i].ANO * 2) + (this.data[i].Cast *1)) / (this.data[i].Celk*2))
      >= 0.85){this.data[i].status="good green"}
    else if(
      (((this.data[i].ANO * 2) + (this.data[i].Cast *1)) / (this.data[i].Celk*2))
      >=0.65){this.data[i].status="warning yellow"}
    else if(
      (((this.data[i].ANO * 2) + (this.data[i].Cast *1)) / (this.data[i].Celk*2))
    <0.65){this.data[i].status="danger red"}
  }
    }

    changeColor2(){
      var i;
      for (i = 0; i < this.data.length; i++) {
        if (this.data[i].typ == 1){this.data[i].status2="denni"}
        else if(this.data[i].typ == 7){this.data[i].status2="tvyroba"}
        else if(this.data[i].typ == 8){this.data[i].status2="tsklad"}
        else if(this.data[i].typ == 14){this.data[i].status2="ctrnact"}
        else if(this.data[i].typ == 30){this.data[i].status2="mesic"}
      }
    }
  
    getData() {
      this.changeColor()
      this.changeColor2()
      return super.getData()
    }
  
    getFilters() {
      return this.typyAuditu.map(type => (
        {label: type.name, func: (item: any) => item.typ === type.num, value: type.num}
      ))
    }
  
    getFilteredType() {
      const activeFilter = this.getActiveFilter()
      return activeFilter ? activeFilter.value : 1 // default typVyrobku
    }
  
    onSubmit(form:NgForm){
      if (form.value.from!='') {this.datum.from = form.value.from}
      if (form.value.to!='') {this.datum.to = form.value.to}
      this.refresh()
    }
  
    getHeading() {
      return 'Seznam auditů LPA'
    }
  
    loadItemData(item) {
      this.comm.getKartaA_pn(item).subscribe(
        (response) => {
          this.datapn = response.json();
          //console.log(this.datapn);
          //this.dateformat();
        },
        (error) => console.log(error)
      )
    }

    onItemClick(item){
      this.poznamka=item.komentar;
      this.comm.auditId.auditId=item.auditId;
      //console.log(item);
      this.activeItem.auditId=item.auditId;
      this.comm.getAnalyzaAuditId(item).subscribe(
       (response) => {
         this.otazky=response.json();
         //console.log(this.otazky);
       },
       (error) => console.log(error));
     }
  
    
  }
