import { Component, OnInit, OnDestroy } from '@angular/core'
import { NgForm } from '@angular/forms'
import BaseEditationComponent from 'src/app/class/BaseEditationComponent'
import 'rxjs/add/operator/filter'
import { NotifierService } from 'angular-notifier'

@Component({
  selector: 'app-kartab-editace',
  templateUrl: './editace.component.html',
  styleUrls: ['./../../../app.component.css'],
})

export class EditaceKartaBComponent extends BaseEditationComponent implements OnInit, OnDestroy {
  
  protected notifier: NotifierService;

  data;
  dataedit;
  response;

  users=[];

  osoba;
  operator;

  souhrn={"id":"", "osoba":"", "operator":"", "datum":"", "note":"", "operator_note":"",  "check_visual":"", "check_place":"", "check_training":"", "operator_visual":"", "operator_place":"", "operator_training":"", "funkcni_zkouska":""
    , "testovaci_sada":"", "kontrola1":"", "kontrola2":"", "kontrola3":"", "kontrola4":""};

  objectKeys = Object.keys;
  fmFieldMappings = {
    imageField: 'image',
  }

  getHeading() {
    return `Editace Karty B`
  }

  ngOnInit() {
    this.getKartaB();
    super.ngOnInit();
  }

  loadData() {
    super.loadData()
    this.loadUsers()
  }

  getKartaB(){
    console.log(this.comm.kartaB);
    this.comm.getKartaB(this.comm.kartaB).subscribe(
      (response) => {this.data=response.json();console.log(this.data)},
      (error) => console.log(error));
      return this.data;
  }

  loadUsers(){
    this.comm.users().index().subscribe(
      (response) => { this.users = response.json();},
      (error) => console.error({error})
    )
  }

  setOsoba(event: any) {
    this.osoba = event.target.value;
  }

  setOperator(event: any) {
    this.operator = event.target.value;
  }

  onSubmit(form: NgForm){
    this.souhrn.id = this.data[0].id;
    if (form.value.datum!=''){this.souhrn.datum=form.value.datum}else{this.souhrn.datum=this.data[0].datum};
  //  if (form.value.note!=''){this.souhrn.note=form.value.note}else{this.souhrn.note=this.data[0].note};
  //  if (form.value.operator_note!=''){this.souhrn.operator_note=form.value.operator_note}else{this.souhrn.operator_note=this.data[0].operator_note};
  //  if (this.osoba!=undefined){this.souhrn.osoba=this.osoba}else{this.souhrn.osoba=this.data[0].osoba};
  //  if (this.operator!=undefined){this.souhrn.operator=this.operator}else{this.souhrn.operator=this.data[0].operator};

  //  this.souhrn.check_visual=form.value.check_visual;
  //  this.souhrn.check_place=form.value.check_place;
  //  if (form.value.check_training!=undefined){this.souhrn.check_training=form.value.check_training}else{this.souhrn.check_training=this.data[0].check_training};

  //  if (form.value.operator_visual!=undefined){this.souhrn.operator_visual=form.value.operator_visual}else{this.souhrn.operator_visual=this.data[0].operator_visual};
  //  if (form.value.operator_place!=undefined){this.souhrn.operator_place=form.value.operator_place}else{this.souhrn.operator_place=this.data[0].operator_place};
  //  if (form.value.operator_training!=undefined){this.souhrn.operator_training=form.value.operator_training}else{this.souhrn.operator_training=this.data[0].operator_training};

  //  this.souhrn.funkcni_zkouska=form.value.funkcni_zkouska;
  //  this.souhrn.testovaci_sada=form.value.testovaci_sada;
    
   

    this.comm.updateKartaB(this.souhrn).subscribe(
      (response) => {this.response=response.json(); console.log(this.response)},
      (error) => console.log(error));
    console.log(this.souhrn);
    this.notifier.notify('success', 'Uloženo')
  }

  

}
