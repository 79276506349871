import { Component, OnInit, OnDestroy } from '@angular/core'
import { NgForm } from '@angular/forms'
import BaseEditationComponent from 'src/app/class/BaseEditationComponent'
import 'rxjs/add/operator/filter'
import { NotifierService } from 'angular-notifier'

@Component({
  selector: 'app-lpa-editace',
  templateUrl: './editace.component.html',
  styleUrls: ['./../../../app.component.css'],
})
export class EditaceLpaComponent extends BaseEditationComponent implements OnInit, OnDestroy {
  

  protected notifier: NotifierService;

  data;
  dataedit;
  response;

  sestavy=[];
  users=[];
  typyAuditu=[];
  otazkyAuditu=[];
  provozovny=[];
  questions=[];

  typ;
  pn;
  osoba;
  operator;
  provoz;

  souhrn={"typ":"", "pn":"", "osoba":"", "operator":"", "provoz":"", "datum":"", "komentar":"", "question":""};

  objectKeys = Object.keys;
  fmFieldMappings = {
    imageField: 'image',
  }

  getHeading() {
    return `Editace auditu`
  }

  ngOnInit() {
    this.getAudit();
    super.ngOnInit();
  }

  loadData() {
    super.loadData()
    this.loadProvozovny()
    this.loadUsers()
    this.loadSestavy()
    this.loadTypyAuditu()
    this.loadotazkyAuditu()
  }

  loadProvozovny(){
    this.comm.provozovny().index().subscribe(
      (response) => { this.provozovny = response.json();},
      (error) => console.error({error})
    )
  }

  loadSestavy(){
    this.comm.products().index().subscribe(
      (response) => { this.sestavy = response.json();},
      (error) => console.error({error})
    )
  }

  loadUsers(){
    this.comm.users().index().subscribe(
      (response) => { this.users = response.json();},
      (error) => console.error({error})
    )
  }

  loadTypyAuditu(){
    this.comm.typyAuditu().index().subscribe(
      (response) => { this.typyAuditu = response.json();},
      (error) => console.error({error})
    )
  }

  
  loadotazkyAuditu(){
    this.comm.otazkyAuditu().index().subscribe(
      (response) => { this.otazkyAuditu = response.json();},
      (error) => console.error({error})
    )
  }

  getAudit(){
    console.log(this.comm.auditId);
    this.comm.getAudit(this.comm.auditId).subscribe(
      (response) => {this.data=response.json();},
      (error) => console.log(error));
      return this.data;
  }

  setTypAuditu(event: any) {
    this.typ = event.target.value;
  }

  setPN(event: any) {
    this.pn = event.target.value;
  }

  setOsoba(event: any) {
    this.osoba = event.target.value;
  }

  setOperator(event: any) {
    this.operator = event.target.value;
  }

  setProvoz(event: any) {
    this.provoz = event.target.value;
  }

  onSubmit(form: NgForm){
    if (form.value.datum!=''){this.souhrn.datum=form.value.datum}else{this.souhrn.datum=this.data[0].datum};
    if (form.value.komentar!=''){this.souhrn.komentar=form.value.komentar}else{this.souhrn.komentar=this.data[0].komentar};
    if (this.typ!=undefined){this.souhrn.typ=this.typ}else{this.souhrn.typ=this.data[0].typ};
    if (this.pn!=undefined){this.souhrn.pn=this.pn}else{this.souhrn.pn=this.data[0].pn};
    if (this.osoba!=undefined){this.souhrn.osoba=this.osoba}else{this.souhrn.osoba=this.data[0].osoba};
    if (this.operator!=undefined){this.souhrn.operator=this.operator}else{this.souhrn.operator=this.data[0].operator};
    if (this.provoz!=undefined){this.souhrn.provoz=this.provoz}else{this.souhrn.provoz=this.data[0].provoz};
    
    var i;
    for (i = 0; i < this.data.length; i++) {
    this.data[i].typ=this.souhrn.typ; 
    this.data[i].pn=this.souhrn.pn; 
    this.data[i].osoba=this.souhrn.osoba; 
    this.data[i].operator=this.souhrn.operator; 
    this.data[i].provoz=this.souhrn.provoz; 
    this.data[i].datum=this.datePipe.transform(this.souhrn.datum, 'yyyy-MM-dd HH:mm'); 
    this.data[i].komentar=this.souhrn.komentar;
    this.data[i].bod=form.value['bod'+i];
    
    this.comm.updateAudit(this.data[i]).subscribe(
      (response) => {this.response=response.json(); console.log(this.response)},
      (error) => console.log(error));
    }
    console.log(this.data);
    this.notifier.notify('success', 'Uloženo')
  }

}
